<div id="contentContainer">
    <div id="logoContainer">
        <a href="https://de.gsk.com/de-de/"><img src="assets/img/gsk_logo.png" /></a>
    </div>
    <div id="application">
        <h1>Impressum</h1>
        <hr />

        <h2>GlaxoSmithKline GmbH &amp; Co. KG</h2>
        <p>Prinzregentenplatz 9<br />D-81675 München</p>
        <p>
            Vertreten durch die Allen Pharmazeutika Gesellschaft m.b.H, Wien<br /><span>Handelsgericht Wien FN 93449 a<br /></span>Geschäftsführung:<br /><span> René Jensen</span>
        </p>
        <p>Tel.: <a href="tel:+4989360440">+ 49 (0) 89 360 44 0</a></p>
        <h3>Medizinisches Info &amp; Service-Center:</h3>
        <p>Tel.: <a href="tel:+498001223355">+ 49 (0) 800 1 22 33 55</a> (08.00 bis 18.00 Uhr)</p>
        <p>
            E-Mail: <a rel="noopener" href="mailto:produkt.info@gsk.com" target="_blank">produkt.info[at]gsk.com</a><a href="Mailto:service.info@gsk.com"><br /></a> Internet:
            <a rel="noopener" href="http://de.gsk.com" target="_blank">http://de.gsk.com</a>
        </p>
        <p>Registergericht: Amtsgericht München</p>
        <p>Handelsregister: HRA 78754</p>
        <p>Umsatzsteuer-Identifikationsnummer: DE 813 233 122</p>
        <p>Zuständige Aufsichtsbehörde<br />Regierung von Oberbayern<br />Maximilianstraße 39 <br />D-80538 München</p>
        <h3>Verantwortlich</h3>
        <p><span>Communications, Government Affairs &amp; Market Access</span><br />GlaxoSmithKline GmbH &amp; Co. KG <br />Prinzregentenplatz 9<br />D-81675 München</p>
        <p>Administrator für de.gsk.com: Katharina Mayer</p>
        <h3><span>Soziale Medien</span></h3>
        <p>
            <span
                >Wir nutzen soziale Medien, um transparent und zeitnah interagieren und kommunizieren zu können. Wir sind in Deutschland und weltweit auf mehreren Social-Media-Kanälen aktiv. Es gelten die Nutzungsbedingungen und
                Verhaltenskodizes der jeweiligen Kanäle, </span
            ><span>weitere Informationen auf unserer <a rel="noopener" href="https://www.gsk.com/en-gb/media/social-media/" target="_blank">globalen Website</a>.</span>
        </p>

        <hr />
    </div>
</div>

<div class="container">
    <div id="logoContainer">
        <a href="https://de.gsk.com/de-de/" class="float-right"><img src="assets/img/gsk_logo.png"></a>
    </div>
    <article>
        <h1><span class="orange" style="font-size: 1.85rem;">Experten-Webinar: Neueste Erkenntnisse aus der PRIMA-Studie</span></h1>
        <h1>09. Dezember 2024, 17:30 – 18:30 Uhr</h1>

        <img class="responsive-img" src="assets/img/landingpages/gsk-zejula-prima-studie/ovarian-cancer-science.jpg"><br/>

        <p>
            Sehr geehrte Damen und Herren,<br/><br/>
            wir freuen uns sehr über Ihr Interesse an unserem bevorstehenden englischsprachigen Webinar mit Prof. Antonio González-Martín.

            Dieses Experten-Webinar bietet Ihnen die einmalige Gelegenheit, gemeinsam mit Fachkolleginnen und -kollegen die neuesten Erkenntnisse aus der PRIMA-Studie zu diskutieren und direkt vom Studienleiter der PRIMA-Studie wertvolle Einblicke in die 1L-Erhaltungstherapie mit ZEJULA beim Ovarialkarzinom zu gewinnen.
        </p>
        <p>
            Um Ihre Anmeldung abzuschließen und Ihnen einen reibungslosen Ablauf zu ermöglichen, bitten wir Sie, uns die folgenden Informationen zur Verfügung zu stellen: 
        </p>

        <div class="app-registration greyBackground" *ngIf="showRegistrationFormView">
            <form name="form" #f="ngForm">
                <div class="cross-validation"> 

                    <div class="form-group">
                        <select id="title" name="title" [(ngModel)]="registration.misc.title" class="form-control">
                            <option [ngValue]="''"> - </option>
                            <option [ngValue]="'Dr.'">Dr.</option>
                            <option [ngValue]="'Prof.'">Prof.</option>
                            <option [ngValue]="'PD Dr.'">PD Dr.</option>
                        </select>
                        <label>{{ "registration.labels.title" | translate }}</label>
                    </div>

                    <div class="form-group">
                        <input id="firstName" name="firstname" #firstNameInput="ngModel" [(ngModel)]="this.registration.firstname" type="text" class="form-control" required />
                        <label>{{ "registration.labels.first_name" | translate }}<sup>*</sup></label>
                        <div class="validation-error alert alert-danger" *ngIf="firstNameInput?.errors?.required && (firstNameInput.dirty || firstNameInput.touched)">
                            <p>{{ "registration.validation.first_name" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="lastName" name="lastName" #lastNameInput="ngModel" [(ngModel)]="this.registration.lastname" type="text" class="form-control" required />
                        <label>{{ "registration.labels.last_name" | translate }}<sup>*</sup></label>
                        <i class="form-group__bar"></i>
                        <div class="validation-error alert alert-danger" *ngIf="lastNameInput.errors?.required && (lastNameInput.dirty || lastNameInput.touched)">
                            <p>{{ "registration.validation.last_name" | translate }}</p>
                        </div>
                    </div>

                    <div class="form-group">
                        <select id="specialization" name="specialization" [(ngModel)]="registration.misc.specialization" class="form-control" required>
                            <option [ngValue]="''" disabled="disabled"> - </option>
                            <option [ngValue]="'internal-medicine-oncologist'">Internistische Onkologie</option>
                            <option [ngValue]="'gynecologist'">Gynäkologie</option>
                            <option [ngValue]="'gynecologist-oncologist'">Gynonkologie</option>
                            <option [ngValue]="'other'">Andere</option>
                        </select>
                        <label>{{ "registration.labels.specialization" | translate }}<sup>*</sup></label>
                    </div>
        
                    <div class="form-group">
                        <input id="email" name="email" #emailInput="ngModel" [(ngModel)]="this.registration.email" type="email" class="form-control" required email />
                        <label>{{ "registration.labels.email" | translate }}<sup>*</sup></label>
                        <div class="validation-error alert alert-danger" *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">
                            <p>{{ "registration.validation.email" | translate }}</p>
                        </div>
                    </div>

                    <div class="form-group">
                        <input id="postcode" name="postcode" #postcodeInput="ngModel" [(ngModel)]="this.registration.misc.postcode" type="text" class="form-control" />
                        <label>{{ "registration.labels.postcode" | translate }}</label>
                        <i class="form-group__bar"></i>
                    </div>

                    <div class="form-group">
                        <input id="efn" name="efn" #efnInput="ngModel" [(ngModel)]="this.registration.misc.efn" type="text" class="form-control" required />
                        <label>{{ "registration.labels.efn" | translate }}<sup>*</sup></label>
                        <i class="form-group__bar"></i>
                        <div class="validation-error alert alert-danger" *ngIf="efnInput.invalid && (efnInput.dirty || efnInput.touched)">
                            <p>{{ "registration.validation.efn" | translate }}</p>
                        </div>
                    </div>

                    <div class="form-group" style="position:relative;">
                        <input id="termsAccepted" #termsAcceptedInput="ngModel" name="termsAccepted" style="position: absolute; left:0px; top:4px; width: 14px; height:14px;" [(ngModel)]="this.registration.misc.termsAccepted" type="checkbox" required />
                        <label id="termsAcceptedLabel" for="termsAccepted" style="position: relative; left:30px; top:0px;">Für die Websites und Formulare von GSK gelten unsere <a href="https://privacy.gsk.com/de-de/privacy-notice/general/" target="_blank">Datenschutzbestimmungen</a> und <a href="https://de.gsk.com/de-de/nutzungsbedingungen/" target="_blank">Nutzungsbedingungen</a>.<sup>*</sup></label>
                        <div class="validation-error alert alert-danger" *ngIf="termsAcceptedInput.errors?.required && (termsAcceptedInput.dirty || termsAcceptedInput.touched)">
                            <p>Bitte stimmen Sie den Datenschutzbestimmungen und Nutzungsbedingungen zu.</p>
                        </div>
                    </div>

                    <div class="form-group" style="position:relative;">
                        <input id="ePermission" #ePermissionInput="ngModel" name="ePermission" style="position: absolute; left:0px; top:4px; width: 14px; height:14px;" [(ngModel)]="this.registration.misc.ePermission" type="checkbox" />
                        <label id="ePermissionLabel" for="ePermission" style="position: relative; left:30px; top:0px;">Ja, ich möchte von GSK per E-Mail über Produkte, Dienstleistungen, Aktionen und Veranstaltungen informiert werden. Ich verstehe, dass ich meine Präferenzen mittels E-Mail an <a href="mailto:digitale.services@gsk.com" style="color:#007bff;">digitale.services@gsk.com</a> jederzeit anpassen bzw. widerrufen kann.</label>
                    </div>
        
                    <div class="form-group">
                        <label>
                            * Pflichtfeld
                        </label>
                    </div>
        
                    <button class="btn btn-primary btn-block" type="submit" (click)="register()" [disabled]="f.invalid">
                        {{ "registration.buttons.register" | translate }}
                    </button>
                </div>
            </form>
        </div>

        <div class="app-registration-successful" *ngIf="showRegistrationSuccessfulView">
            <h4>{{ "registration.titles.registration_done" | translate }}</h4>
            <p>Ihre Registrierung war erfolgreich. Sie werden in Kürze eine Bestätigungs-E-Mail erhalten. </p>
        </div>

        <section class="relative position-relative" style="display: block; position: relative;">
            <table class="mt-3 mb-3" style="width:100%;">
                <tr class="orange strong">
                    <td>5 min</td>
                    <td>Welcome and Introduction</td>
                </tr>
                <tr class="strong">
                    <td></td>
                    <td>Katharina Jansen</td>
                </tr>
                <tr class="orange strong">
                    <td>10 min</td>
                    <td>Introduction to the PRIMA Study and its Methodology</td>
                </tr>
                <tr class="strong">
                    <td></td>
                    <td>Prof. Antonio González-Martín</td>
                </tr>
                <tr class="orange strong">
                    <td>15 min</td>
                    <td>Presentation of the Final PRIMA Study Results</td>
                </tr>
                <tr class="strong">
                    <td></td>
                    <td>Prof. Antonio González-Martín</td>
                </tr>
                <tr class="orange strong">
                    <td>10 min</td>
                    <td>Interpretation and Clinical Implications</td>
                </tr>
                <tr class="strong">
                    <td></td>
                    <td>Prof. Antonio González-Martín</td>
                </tr>
                <tr class="orange strong">
                    <td>15 min</td>
                    <td>Q&A Session</td>
                </tr>
                <tr class="strong">
                    <td></td>
                    <td>Prof. Antonio González-Martín</td>
                </tr>
                <tr class="orange strong">
                    <td>5 min</td>
                    <td>Webinar Closing</td>
                </tr>
                <tr class="strong">
                    <td></td>
                    <td>Katharina Jansen</td>
                </tr>
            </table>
            <img class="md-position-absolute responsive-img table-img" src="/assets/img/landingpages/gsk-zejula-prima-studie/Dr._Gonzalez.jpg"/>
        </section>

        <!--       
        <section class="icons">
            <div class="row">
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="/assets/img/landingpages/onko-campus-hcp/GSK_Gyn-Campus_FL_A4_240307_V2.pdf" target="_blank" onclick="clicktracker.click('landingpage -> agenda');">
                        <p><mat-icon>assignment</mat-icon></p>
                        <p>Agenda</p>
                    </a>
                </div>
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="/assets/img/landingpages/onko-campus-hcp/GSK_Gyn-Onko-Campus-HCP.ics" onclick="clicktracker.click('landingpage -> calendar');">
                        <p><mat-icon>date_range</mat-icon></p>
                        <p>Kalendereintrag</p>
                    </a>
                </div>
            </div>
        </section> 
        -->

        <section>
            <h2>iPad-Nutzer:</h2>
            <p>Bitte stellen Sie sicher, dass auf Ihrem iPad alle aktuellen Updates installiert sind.</p>
        </section>

        <section>
            <h2>Laptop-Nutzer:</h2>
            <p>Wir bitten Sie, einen der folgenden Browser für den Zugang zum Event zu benutzen: Edge, Chrome oder Firefox</p>
        </section>

        <section>
            <h2>Servicekontakt:</h2>
            <p>Bei technischen Schwierigkeiten können Sie sich jederzeit an unseren Support wenden:</p>

            <p>Tel.: 02244 – 87 84 811</p>
            <p>E-Mail: <a href="mailto:support@infill.com">support@infill.com</a></p>
        </section>

        <p class="footnote">
            <span style="font-size: 0.85rem;">NP-DE-NRP-WCNT-240011 (11/2024)</span>
        </p> 

    </article>
</div>

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { OrganizationBaseComponent } from "../OrganizationBaseComponent";
import { AuthenticationService } from "../../services/authentication.service";
import { IOAuthLoginResult } from "../../models/IOAuthLoginResult";
import { IOrganizationSettings, OAuthStorageAdapter } from "../../OAuthStorageAdapter";
import { environment } from "../../../environments/environment";
@Component({
    selector: "app-fastlogin",
    template: `<div></div>`
})
export class FastLoginComponent extends OrganizationBaseComponent implements OnInit {
    private organizationSettings?: IOrganizationSettings;

    constructor(
        private authenticationService: AuthenticationService,
        protected router: Router,
        private oauthStorageAdapter: OAuthStorageAdapter,
        protected route: ActivatedRoute
    ) {
        super(router, route);
    }

    ngOnInit(): void {
        super.ngOnInit();
        const token = this.route.snapshot.paramMap.get("token");

        this.organizationSettings = environment.organizations.find((org) => org.key === this.organizationKey);
        if (this.organizationSettings?.blockLoginUntil) {
            const blockLoginUntil = moment(this.organizationSettings.blockLoginUntil, environment.blockLoginUntilTimeformat);
            const now = moment();

            if (now.isBefore(blockLoginUntil)) {
                this.router.navigate(["/login/", this.organizationKey]);
                return;
            }
        }

        this.subscriptions.push(
            this.authenticationService.fastlogin(token, this.organizationKey).subscribe(
                (result: IOAuthLoginResult) => {
                    this.oauthStorageAdapter.setCurrentOauthResult(result);
                    this.oauthStorageAdapter.setCurrentOrganizationKey(this.organizationKey);

                    if (this.organizationSettings?.loginToLectureHall) {
                        this.router.navigate(["/lecturehall"]);
                    } else {
                        this.router.navigate(["/reception"]);
                    }
                },
                (err) => {
                    this.router.navigate(["/404"]);
                }
            )
        );
    }
}

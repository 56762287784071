export const environment = {
    production: true,
    environmentName: "production",
    version: require("../../package.json").version,
    httpRequestTimeoutSeconds: 120,
    utcDateFormat: "yyyy-MM-dd HH:mm:ss",
    baseAPI: "https://gsk-meeting-platform-api-gepxeskrtq-ey.a.run.app/api/v1/",
    oauthBaseAPI: "https://gsk-meeting-platform-api-gepxeskrtq-ey.a.run.app/api/v1/auth/",
    resetPasswordAPI: "https://gsk-meeting-platform-api-gepxeskrtq-ey.a.run.app/api/v1/resetpassword/",
    registrationsAPI: "https://gsk-meeting-platform-api-gepxeskrtq-ey.a.run.app/api/v1/registration/",
    breakoutgroupAPI: "https://gsk-meeting-platform-api-gepxeskrtq-ey.a.run.app/api/v1/public/breakoutgroups",
    defaultLanguage: "en",
    webinarTimeformat: "YYYY-MM-DD HH:mm:ss ZZ",
    blockLoginUntilTimeformat: "YYYY-MM-DD HH:mm:ss ZZ",
    hideBreakoutButtonUntilTimeformat: "YYYY-MM-DD HH:mm:ss ZZ",
    logErrorInConsole: false,
    organizations: [
        // {
        //     key: "wer-impft-mich",
        //     id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
        //     disable3qsdnChat: true,
        //     webinarStart: "2020-09-16 15:00:00 +0200",
        //     waitingRoomFeature: false,
        //     panoSwf: "assets/panos/wer-impft-mich/assets/js/krpano.swf",
        //     panoXml: "assets/panos/wer-impft-mich/gsk_impf_mich.xml",
        //     waitingRoomAssets: "/assets/img/lmu/waitingroom/",
        //     lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
        //     meetingBg: "/assets/img/lmu/meeting_bg.jpg",
        //     showStatusBox: false,
        //     tawkId: "",
        //     postLoginDisclaimer: {
        //         show: true,
        //         i18nTitleKey: "disclaimer.lmu.title",
        //         i18nContentKey: "disclaimer.lmu.content",
        //         i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
        //     },
        //     meetps: "https://app.meet.ps/wer-impft-mich",
        //     losTrackingEndpoint: "https://infill-service-lostracking-gcr-bpnf6tdpoq-ey.a.run.app/",
        //     clickTracking: {
        //         endpoint: "https://infill-service-clicktracking-gcr-bpnf6tdpoq-ey.a.run.app",
        //         token: "3pYFUDaUQDm6YgQguqwCVZ5JVVBhaDkV2nEhJ2XbL9TR67WzcnFCWLtgFzpRjsT7",
        //         tenantId: "wer-impft-mich"
        //     },
        //     blockLoginUntil: "2021-09-09 01:00:00 +0200",
        //     loginDateTextFormat: "DD.MM.YYYY",
        //     hideBreakoutButtonUntil: "2999-12-01 18:00:00 +0200",
        //     i18nBlockedLoginTextKey: "login.werImpftMich.loginBlocked"
        // },
        {
            key: "onko-campus-hcp",
            id3qsdn: "afc693a8-a5b7-45a5-9aa1-33fadad09af6",
            disable3qsdnChat: true,
            webinarStart: "2022-05-10 16:30:00 +0200",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/onko-campus/assets/js/krpano.swf",
            panoXml: "assets/panos/onko-campus/gsk-3.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "5fa15019e019ee7748f05489",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://meet.ps/Onco-HCP",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-bpnf6tdpoq-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-bpnf6tdpoq-ey.a.run.app",
                token: "3pYFUDaUQDm6YgQguqwCVZ5JVVBhaDkV2nEhJ2XbL9TR67WzcnFCWLtgFzpRjsT7",
                tenantId: "onko-campus-hcp"
            },
            blockLoginUntil: "3000-09-09 10:00:00 +0200",
            i18nBlockedLoginTextKey: "login.mfaHcp.loginBlocked",
            loginDateTextFormat: "DD.MM.YYYY",
            hideBreakoutButtonUntil: "9999-31-31 18:30:00 +0100",
            loginToLectureHall: true
        },
        {
            key: "onko-campus-mfa",
            id3qsdn: "adfe1f5d-8c3d-11ed-bf6a-3cecef385192",
            disable3qsdnChat: true,
            webinarStart: "2022-05-10 16:30:00 +0200",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/onko-campus/assets/js/krpano.swf",
            panoXml: "assets/panos/onko-campus/gsk-3.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "61b1e2f580b2296cfdd0dc07",
            tawkSuffix: "1fmfdt4fu",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://meet.ps/Onco-MFA",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-bpnf6tdpoq-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-bpnf6tdpoq-ey.a.run.app",
                token: "3pYFUDaUQDm6YgQguqwCVZ5JVVBhaDkV2nEhJ2XbL9TR67WzcnFCWLtgFzpRjsT7",
                tenantId: "onko-campus-mfa"
            },
            blockLoginUntil: "3000-09-09 10:00:00 +0200",
            i18nBlockedLoginTextKey: "login.mfaHcp.loginBlocked",
            loginDateTextFormat: "DD.MM.YYYY",
            hideBreakoutButtonUntil: "9999-31-31 18:30:00 +0100",
            loginToLectureHall: true
        },
        {
            key: "prima-studie",
            id3qsdn: "afc693a8-a5b7-45a5-9aa1-33fadad09af6",
            disable3qsdnChat: true,
            webinarStart: "2024-12-09 17:30:00 +0200",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/onko-campus/assets/js/krpano.swf",
            panoXml: "assets/panos/onko-campus/gsk-3.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "5fa15019e019ee7748f05489",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://meet.ps/Onco-HCP",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-bpnf6tdpoq-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-bpnf6tdpoq-ey.a.run.app",
                token: "3pYFUDaUQDm6YgQguqwCVZ5JVVBhaDkV2nEhJ2XbL9TR67WzcnFCWLtgFzpRjsT7",
                tenantId: "gsk-zejula-prima-studie"
            },
            blockLoginUntil: "3000-09-09 10:00:00 +0200",
            i18nBlockedLoginTextKey: "login.primaStudie.loginBlocked",
            loginDateTextFormat: "DD.MM.YYYY",
            hideBreakoutButtonUntil: "9999-31-31 18:30:00 +0100",
            loginToLectureHall: true
        },
        {
            key: "gsk-zejula-neuigkeiten-oc",
            id3qsdn: "afc693a8-a5b7-45a5-9aa1-33fadad09af6",
            disable3qsdnChat: true,
            webinarStart: "2024-11-26 18:00:00 +0200",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/onko-campus/assets/js/krpano.swf",
            panoXml: "assets/panos/onko-campus/gsk-3.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "5fa15019e019ee7748f05489",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://meet.ps/Onco-HCP",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-bpnf6tdpoq-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-bpnf6tdpoq-ey.a.run.app",
                token: "3pYFUDaUQDm6YgQguqwCVZ5JVVBhaDkV2nEhJ2XbL9TR67WzcnFCWLtgFzpRjsT7",
                tenantId: "gsk-zejula-neuigkeiten-oc"
            },
            blockLoginUntil: "3000-09-09 10:00:00 +0200",
            i18nBlockedLoginTextKey: "login.neuigkeitenOc.loginBlocked",
            loginDateTextFormat: "DD.MM.YYYY",
            hideBreakoutButtonUntil: "9999-31-31 18:30:00 +0100",
            loginToLectureHall: true
        }
    ]
};

import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { BaseComponent } from "./BaseComponent";

@Component({ selector: "", template: `<div></div>` })
export abstract class OrganizationBaseComponent extends BaseComponent implements OnInit, OnDestroy {
    public organizationKey: string;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit(): void {
        this.organizationKey = this.route.snapshot.paramMap.get("organizationKey");
        if (!environment.organizations.find((org) => org.key === this.organizationKey)) {
            this.router.navigate(["/404"]);
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}

import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { RegistrationService } from "../../services/registration.service";
import { OrganizationBaseComponent } from "../OrganizationBaseComponent";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-reset-password",
    templateUrl: "./reset-password.component.html",
    styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent extends OrganizationBaseComponent implements OnInit {
    public resetPasswordEmail: string = "";
    public resetPasswordToken: string = "";
    public resetPassword: string = "";
    public resetPasswordConfirm: string = "";

    public showTriggerPasswordResetView: boolean = true;
    public showPerformPasswordResetView: boolean = false;
    public showPasswordResetTriggeredView: boolean = false;

    constructor(
        private registrationService: RegistrationService,
        protected route: ActivatedRoute,
        protected router: Router
    ) {
        super(router, route);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.subscriptions.push(
            this.route.queryParamMap.subscribe((params) => {
                const token = params.get("token");
                if (token) {
                    this.resetPasswordToken = token;
                    this.renderPasswordResetView();
                }
            })
        );
    }

    onSendLink(): void {
        this.triggerPasswordReset(this.resetPasswordEmail);
    }

    onPerformPasswordReset(): void {
        this.performPasswordReset(this.resetPasswordToken);
    }

    triggerPasswordReset(email: string): void {
        this.subscriptions.push(
            this.registrationService.initiatePasswordReset(email, this.organizationKey).subscribe((result: any) => {
                this.showTriggerPasswordResetView = false;
                this.showPasswordResetTriggeredView = true;
            })
        );
    }

    renderPasswordResetView(): void {
        this.showTriggerPasswordResetView = false;
        this.showPerformPasswordResetView = true;
    }

    performPasswordReset(token: string): void {
        this.subscriptions.push(
            this.registrationService.performPasswordReset(token, this.resetPassword, this.resetPasswordConfirm).subscribe((result: any) => {
                this.router.navigate(["/login", this.organizationKey]);
            })
        );
    }
}

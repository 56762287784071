import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ReceptionComponent } from "./components/reception/reception.component";
import { LoginComponent } from "./components/login/login.component";
import { AuthenticationGuard } from "./AuthenticationGuard";
import { RegistrationComponent } from "./components/registration/registration.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { FullLayoutComponent } from "./components/full-layout/full-layout.component";
import { SingleLayoutComponent } from "./components/single-layout/single-layout.component";
import { AboutComponent } from "./components/about/about.component";
import { DataLegalComponent } from "./components/data-legal/data-legal.component";
import { LectureHallComponent } from "./components/lecturehall/lecturehall.component";
import { WaitingRoomComponent } from "./components/waitingroom/waitingroom.component";
import { FastLoginComponent } from "./components/fastlogin/fastlogin.component";
import { WerImpftMichRegistrationComponent } from "./components/landingpages/wer-impft-mich/registration.component";
import { OnkoCampusHcpRegistrationComponent } from "./components/landingpages/onko-campus-hcp/registration.component";
import { OnkoCampusMfaRegistrationComponent } from "./components/landingpages/onko-campus-mfa/registration.component";
import { GSKZejulaPrimaStudieRegistrationComponent } from "./components/landingpages/gsk-zejula-prima-studie/registration.component";
import { GSKZejulaNeuigkeitenOcRegistrationComponent } from "./components/landingpages/gsk-zejula-neuigkeiten-oc/registration.component";
import { GSKTagungComponent } from "./components/landingpages/gsk-tagung/gsktagung.component";

const routes: Routes = [
    {
        path: "",
        component: SingleLayoutComponent,
        canActivate: [AuthenticationGuard],
        children: [
            {
                path: "",
                redirectTo: "reception",
                pathMatch: "full"
            },
            {
                path: "reception",
                component: ReceptionComponent,
                canActivate: [AuthenticationGuard]
            },
            {
                path: "waitingroom",
                component: WaitingRoomComponent,
                canActivate: [AuthenticationGuard]
            },
            {
                path: "lecturehall",
                component: LectureHallComponent,
                canActivate: [AuthenticationGuard]
            }
        ]
    },
    {
        path: "",
        children: [{ path: "gsk-tagung", component: GSKTagungComponent, pathMatch: "full" }]
    },
    {
        path: "",
        component: FullLayoutComponent,
        children: [
            { path: "enter/:organizationKey/:token", component: FastLoginComponent, pathMatch: "full" },
            { path: "login/:organizationKey", component: LoginComponent, pathMatch: "full" },
            { path: "registration/:organizationKey", component: RegistrationComponent, pathMatch: "full" },
            { path: "confirm/:organizationKey", component: RegistrationComponent, pathMatch: "full" },
            { path: "reset_password/:organizationKey", component: ResetPasswordComponent, pathMatch: "full" },
            { path: "perform_password_reset/:organizationKey", component: ResetPasswordComponent, pathMatch: "full" },
            { path: "data_legal", component: DataLegalComponent, pathMatch: "full" },
            { path: "imprint", component: AboutComponent, pathMatch: "full" },
            { path: "landingpage/wer-impft-mich", component: WerImpftMichRegistrationComponent, pathMatch: "full" },
            { path: "landingpage/onko-campus-hcp", component: OnkoCampusHcpRegistrationComponent, pathMatch: "full" },
            { path: "landingpage/onko-campus-mfa", component: OnkoCampusMfaRegistrationComponent, pathMatch: "full" },
            { path: "landingpage/PRIMA-Studie", component: GSKZejulaPrimaStudieRegistrationComponent, pathMatch: "full" },
            { path: "landingpage/prima-studie", component: GSKZejulaPrimaStudieRegistrationComponent, pathMatch: "full" },
            // { path: "landingpage/neuigkeiten-oc", component: GSKZejulaNeuigkeitenOcRegistrationComponent, pathMatch: "full" },
            {
                path: "404",
                component: AboutComponent,
                pathMatch: "full"
            },
            {
                path: "**",
                redirectTo: "/404"
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}

<!-- Alerts -->
<div class="container" id="alertContainer">
    <app-alert></app-alert>
</div>
<!-- Alerts -->
<router-outlet></router-outlet>
<!-- Footer -->
<footer class="page-footer">
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">
        Die Inhalte richten sich an medizinische Fachkreise in Deutschland | © 2023 Copyright:
        <a href="https://de.gsk.com/de-de/"> https://de.gsk.com/de-de/</a>
        <br />
        <div class="footer-links">
            <!-- <a href="#/imprint">{{ "footer.links.imprint" | translate }}</a> |
            <a href="#/data_legal">{{ "footer.links.privacy" | translate }}</a> |
            {{ 'footer.support' | translate }} -->

            <a href="https://de.gsk.com/de-de/uber-uns/">Über GSK</a> | <a href="https://de.gsk.com/de-de/impressum/">Impressum</a> | <a href="https://de.gsk.com/de-de/nutzungsbedingungen/">Nutzungsbedingungen</a> |
            <a href="https://privacy.gsk.com/de-de/privacy-notice/">Datenschutzbestimmungen</a> | <a href="https://gskpro.com/de-de/kontakt/">Kontakt/Nebenwirkung melden</a> |
            {{ "footer.support" | translate }}
        </div>
    </div>
    <!-- Copyright -->
</footer>
<!-- Footer -->

<router-outlet>
</router-outlet>


<div class="disclaimerBackground" *ngIf="showDisclaimer"></div>
<div class="disclaimerContainer" *ngIf="showDisclaimer">
    <h1>{{ disclaimerSettings.i18nTitleKey | translate }}</h1>
    <p [innerHTML]="disclaimerSettings.i18nContentKey | translate"></p>
    <div class="disclaimerButtonWrapper">
        <span (click)="agreeDisclaimer()">{{ disclaimerSettings.i18nAgreeButtonKey | translate }}</span>
    </div>
</div>

<div id="helpBackground" *ngIf="helpVisible" (click)="toggleHelp()"></div>
<div id="helpFrame" *ngIf="helpVisible">
    <div>
        <br/>
        <b>Servicekontakt:</b><br/>
        Tel.: 02244 – 87 84 811 <br/>
        E-Mail: <a href="mailto:support@infill.com">support@infill.com</a><br/><br/>

        <!-- <b>Organisationsteam MCI:</b><br/>
        Christina Behring <br/>
        Tel.: 089 – 54 90 96 18 <br/>
        E-Mail: <a href="mailto:christina.behring@mci-group.com">christina.behring@mci-group.com</a> <br/>
        E-Mail: <a href="mailto:gsk@mci-group.com">gsk@mci-group.com</a><br/> -->
    </div>
</div>
<button *ngIf="helpVisible" id="helpFrameCloseButton" (click)="toggleHelp()" title="Close">
    <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
</button>

<div class="supportFooter"><a href="https://de.gsk.com/de-de/impressum/" target="_blank">Impressum</a> | <a href="https://de.gsk.com/de-de/nutzungsbedingungen/" target="_blank">Nutzungsbedingungen</a> | <a href="https://privacy.gsk.com/de-de/privacy-notice/general/" target="_blank">Datenschutzbestimmungen</a> | <a href="https://de.gsk.com/de-de/uber-uns/" target="_blank">Über GSK</a> | <a href="https://gskpro.com/de-de/kontakt/" target="_blank">Kontakt/Nebenwirkungen melden</a> | <span style="cursor:pointer;" (click)="toggleHelp()">Support</span></div>
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { io, Socket } from "socket.io-client";
import { IOrganizationSettings, OAuthStorageAdapter } from "../OAuthStorageAdapter";

@Injectable()
export class LosTrackingService {
    public socket: Socket | null = null;
    private organizationSettings: IOrganizationSettings;

    constructor(
        private oauthStorageAdapter: OAuthStorageAdapter,
        private http: HttpClient
    ) {
        this.organizationSettings = this.oauthStorageAdapter.getOrganizationSettings();
    }

    public cloudflareLookup(): Observable<string> {
        const headers = new HttpHeaders().set("Content-Type", "text/plain; charset=utf-8");

        return this.http.get(`https://www.cloudflare.com/cdn-cgi/trace`, {
            headers,
            responseType: "text"
        });
    }

    public setupSocketConnection(jwt: string, screenName: string, countryCode: string): void {
        if (this.organizationSettings.losTrackingEndpoint) {
            this.socket = io(this.organizationSettings.losTrackingEndpoint, {
                transports: ["websocket"],
                secure: true
            });
            this.socket.on("connect", () => {
                this.losConfig(jwt, screenName, countryCode);
            });
            this.socket.on("reconnect", () => {
                this.losConfig(jwt, screenName, countryCode);
            });
        }
    }

    public closeSocketConnection(): void {
        this.socket?.close();
    }

    private losConfig(jwt: string, screenName: string, countryCode: string): void {
        this.socket?.emit("losConfig", {
            jwt,
            userAgent: window.navigator.userAgent,
            screenName,
            countryCode
        });
    }
}
